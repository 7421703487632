.relative {
  position: relative;
}

.space-around {
  justify-content: space-around;
}

.space-between {
  justify-content: space-between !important;
}

.clickable:hover {
  cursor: pointer !important;
  text-decoration: underline !important;
}

.flex {
  display: flex;
}

.border-none {
  border: none !important;
}

.center {
  justify-content: center;
}

.ml-0 {
  margin-left: 0 !important;
}

.pdf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: gainsboro;
  height: 900px;
}

.icon-sm {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}
